import React from "react";

export const Features = (props) => {
  console.log({ props });
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="line"></div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Dịch vụ</h2>
        </div>
        <div className="paragraph">
          <p>
            "Dịch vụ của chúng tôi không chỉ là việc làm mà là cam kết của sự
            chuyên nghiệp và sáng tạo. Chúng tôi hiểu rằng mỗi dự án là một cơ{" "}
          </p>

          <p>
            {" "}
            hội để thể hiện tài năng và đem lại giá trị thực sự cho khách hàng.
            Với đội ngũ chuyên gia đầy kinh nghiệm và sự tận tâm không ngừng,
            chúng tôi cam kết đồng hành cùng bạn trong việc đạt được những mục
            tiêu và thành công vượt xa mong đợi."
          </p>
        </div>
        <div className="flex-container">
          <div className="card">
            <img src="/img/service/1.png" alt="Imag" />
          </div>
          <div className="card">
            <img src="/img/service/2.png" alt="Imag" />
          </div>
          <div className="card">
            <img src="/img/service/3.png" alt="Imag" />
          </div>
          <div className="card">
            <img src="/img/service/4.png" alt="Imag" />
          </div>
        </div>
      </div>
    </div>
  );
};
