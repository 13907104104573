import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="header-greeting left">
          <h1>Golf solutions</h1>
          <h1 className="golf-text">Livescore</h1>
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
          <a href="#contact" className="btn btn-custom btn-lg page-scroll">
            Liên hệ ngay
          </a>{" "}
        </div>
        <div className="header-banner right">
          <img src="img/live.png" className="right-image" alt="LIVE"></img>
          <img src="img/score.png" alt="SCORE" className="right-image"></img>
          <img
            src="img/golfer.png"
            className="overflow-image"
            alt="GOLFER"
          ></img>
        </div>
      </div>
    </header>
  );
};
