import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="line"></div>
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>MÔI TRƯỜNG LÀM VIỆC</h2>
          <p>
            Công ty GS luôn tạo ra môi trường và những hoạt động ngoại khóa
            thường niên dành cho nhân viên của công ty để mang lại sự mới mẻ,
            năng động và luôn có những điều đặc biệt mà công ty GS mang đến.
          </p>
        </div>
        <div className="thumbnail">
          <div className="thumbnail-image">
            <img src="img/team/1.png" alt="..." className="team-img" />
          </div>
          <div className="thumbnail-image">
            <img src="img/team/2.png" alt="..." className="team-img" />
          </div>
          <div className="thumbnail-image">
            <img src="img/team/3.png" alt="..." className="team-img" />
          </div>
        </div>
      </div>
    </div>
  );
};
