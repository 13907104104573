import React from "react";
import { Logo } from "./logo";

export const Navigation = (props) => {
  const navbarItems = [
    {
      title: "Trang chủ",
      href: "#",
    },
    {
      title: "Về chúng tôi",
      href: "#about",
    },
    {
      title: "Dịch vụ",
      href: "#features",
    },
    {
      title: "Dự án",
      href: "#portfolio",
    },
    {
      title: "Giải đấu",
      href: "#tournament",
    },
  ];
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <Logo url={"img/logo.png"}></Logo>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {navbarItems.map((item) => {
              return (
                <li>
                  <a href={item.href} className="page-scroll">
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
