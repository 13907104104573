import React from "react";

export const Project = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="line"></div>
      <div className="project-container">
        <img src="img/service/5.png" alt="LIVE"></img>
      </div>
    </div>
  );
};
